import { Account } from "../account/account";
import { Amount } from "../../core/amount/amount";
import { Recipient } from "../recipients/recipient";

export type AccountOrRecipient = Account & Recipient;

export enum ServiceLevel {
  XCT = "XCT",
  SEPA = "SEPA",
  BACI = "BACI",
  KNGA = "KNGA",
  ON_US = "ON_US",
  SCT_INST = "SCT_INST",
}

export enum PaymentType {
  PHONE = "phone",
  IBAN = "IBAN",
  OTHER = "other",
}

export enum PaymentIdentificationMode {
  IBAN = "IBAN",
  PHONE = "PHONE",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  IDENTITY_ID = "IDENTITY_ID",
}

export enum PaymentAcceptedAddress {
  STRUCTURED = "STRUCTURED",
  UNSTRUCTURED = "UNSTRUCTURED",
  UNSTRUCTURED_OR_NULL = "UNSTRUCTURED_OR_NULL",
  BOTH = "BOTH",
  NO_ADDRESS = "NO_ADDRESS",
}

export const NETWORK_ACCEPTED_ADDRESSES_NEEDED: PaymentAcceptedAddress[] = [
  PaymentAcceptedAddress.BOTH,
  PaymentAcceptedAddress.STRUCTURED,
  PaymentAcceptedAddress.UNSTRUCTURED,
  PaymentAcceptedAddress.UNSTRUCTURED_OR_NULL,
];

type StructuredAddress = {
  streetName?: string;
  buildingNumber?: string;
  postCode?: string;
  townName?: string;
};

export type PaymentAddress = {
  structuredAddress?: StructuredAddress;
  country: string;
  addressLines?: string;
};

export interface PaymentNetwork {
  name: string;
  serviceLevel: ServiceLevel;
  activated: boolean;
  identificationMode: PaymentIdentificationMode[];
  acceptedAddresses: PaymentAcceptedAddress;
}

export interface PaymentContracts {
  creditTransferEmission: PaymentNetwork[];
  creditTransferReception: PaymentNetwork[];
}

export type CustomerInstructionInformation = {
  paymentInstrument: string;
  paymentService: string;
  batchBooking: boolean;
  customerInstructionReference: string;
  requestedExecutionDate?: string;
  customerInstructionTypeInformation: {
    categoryPurpose: "NULL";
    serviceLevel?: ServiceLevel;
  };
};

export type PaymentRTransaction = {
  paymentRTransactionAmountInformation: {
    returnedAmount?: Amount;
    chargeBearer?: string;
    chargesAmount?: Amount;
  };
  paymentRTransactionInformation: {
    paymentRTransactionIdentification: {
      originalTransactionId: string;
      relatedTransactionInternalId: string;
      originalMessageNameId?: string;
      originalMessageId?: string;
      originalEndToEndId?: string;
      originalInterbankingSettlementDate?: Date;
    };
    paymentRTransactionReturnInformation: {
      originator: object;
      reason?: string;
      decisionStatus?: string;
      externalProcessingDate?: Date;
      inquiryInformation?: string;
    };
  };
  externalData?: object;
};

export type SubmitCreditTransferPaymentRTransactionBody = {
  customerInstructionInformation: CustomerInstructionInformation;
  externalData?: object;
  paymentRTransaction: PaymentRTransaction;
};

type CustomerInstructionOrderingParties = {
  initiatingParty: {
    name?: string;
    // postalAddress: {
    // 	country: number;
    // };
    id: {
      value?: string;
      type?: PaymentType;
    };
  };
  debtor: {
    name?: string;
    // postalAddress: {
    // 	country: number;
    // };
    accountId: {
      value?: string;
      type?: PaymentType;
      bankId?: {
        value?: string;
        type?: PaymentType;
      };
    };
  };
};

export type PaymentTransaction = {
  paymentTransactionDedicatedInformations?: {
    remittanceInformation: {
      value: string;
      type: string;
    };
  };
  paymentTransactionAmountInformation: {
    instructedAmount?: Amount;
    chargeBearer: string;
    chargesAmount: Amount;
  };
  paymentTransactionParties: {
    initiatingParty: {
      name: string;
      id: {
        value: number;
        type: string;
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
    debtor: {
      name: string;
      id: {
        value: string;
        type: string;
      };
      accountId: {
        value: string;
        type: string;
        bankId: {
          value: string;
          type: string;
        };
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
    creditor: {
      name: string;
      id: {
        value: string;
        type: string;
      };
      accountId: {
        value: string;
        type: string;
        bankId: {
          value: string;
          type: string;
        };
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
    ultimateCreditor: {
      name: string;
      id: {
        value: string;
        type: string;
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
  };
  paymentTransactionInformation: {
    paymentInstrument: string;
    paymentTransactionIdentification: {
      messageId: string;
      instructionId: string;
      endToEndId: string;
      externalTransactionId: string;
      customerInstructionId: string;
      id: string;
      clearingChannel: string;
    };
    paymentTransactionTypeInformation: {
      serviceLevel: string;
    };
    direction: string;
    transactionType: string;
    mandateRelatedInformation: {
      mandateIdentification: string;
      dateOfSignature: Date;
    };
    sequenceType: string;
    creditorSchemeId: string;
    creditorSchemeName: string;
    requestedCollectionDate: Date;
    localInstrument: string;
  };
  paymentTransactionInternalInformation: {
    id: string;
    status: {
      value: string;
      label: string;
    };
    history: any[];
    statusReasonInformation: {
      originator: string;
      reason: {
        code: string;
        message: string;
      };
    };
    contractId: number;
  };
  paymentTransactionDateInformation?: {
    creationDate: string;
    instructedDate: Date;
    settlementDate: Date;
  };
  customerInstructionId?: string;
  paymentInstructionId?: string;
  externalData?: {
    externalDataKey: string;
    externalDataBool: boolean;
    externalDataArray: number[];
  };
  links?: {
    rel: string;
    href: string;
  }[];
};

export interface CustomerInstruction {
  customerInstructionInformation: CustomerInstructionInformation;
  customerInstructionOrderingParties: CustomerInstructionOrderingParties;
  paymentTransaction: PaymentTransaction;
}

export interface CustomerInstructionResult {
  id: number;
  status: {
    value: string;
    label: string;
  };
  customerInstructionInformation?: CustomerInstructionInformation;
  calculatedNbPaymentTransactions?: number;
  calculatedAmount?: Amount;
  customerInstructionOrderingParties?: CustomerInstructionOrderingParties;
  paymentTransaction?: PaymentTransaction;
  strongAuthenticationReference?: string;
}

export function getBlankCustomerInstruction(): CustomerInstruction {
  return {
    customerInstructionInformation: {
      paymentInstrument: "CreditTransfer",
      paymentService: "PAYMENTS",
      batchBooking: true,
      customerInstructionReference: "CINSTR202302011556",
      requestedExecutionDate: undefined,
      customerInstructionTypeInformation: {
        categoryPurpose: "NULL",
        serviceLevel: undefined,
      },
    },
    customerInstructionOrderingParties: {
      initiatingParty: {
        name: undefined,
        // postalAddress: {
        // 	country: 250,
        // },
        id: {
          value: undefined,
          type: undefined,
        },
      },
      debtor: {
        name: undefined,
        // postalAddress: {
        // 	country: 250,
        // },
        accountId: {
          value: undefined,
          type: undefined,
          bankId: {
            value: undefined,
            type: undefined,
          },
        },
      },
    },
    paymentTransaction: {
      paymentTransactionAmountInformation: {
        instructedAmount: undefined,
      },
      paymentTransactionParties: {
        creditor: {
          name: undefined,
          // postalAddress: {
          // 	country: "",
          // },
          accountId: {
            value: undefined,
            type: undefined,
          },
        },
      },
    },
  };
}
