export class Features {
  public accounts = false;
  public payment = false;
  public payout = false;
  public p2pTransfer = false;
  public p2pSimpleTransfer = false;
  public p2pCashTransfer = false;
  public p2pDedicatedTransfer = false;
  public billPayment = false;
  public transactionCode = false;
  public consultLoans = false;
  public subscribeLoan = false;
  public cards = false;
  public virtualCardCreation = false;
  public clientInformationView = false;
  public clientInformationUpdate = false;
  public pincodeUpdate = false;
  public notifications = false;
  public externalLoanManagement = false;
  public withdraw = false;
  public bankToWallet = false;
  public walletRechargeByCard = false;
  public acceptorSearch = false;
  public manageQrCode = false;
  public manageCategorizationSpendings = false;
  public bicReferential = false;
  public externalAccountsManagement = false;
  public externalAccountsUsage = false;
  public beneficiariesManagement = false;
  public beneficiariesUsage = false;
  public sdaCustomerOnboardingCreate = false;
  public sdaCustomerOnboardingView = false;
  public sdaCustomerOnboardingUpdate = false;
  public sdaCustomerOnboardingSubmit = false;
  public strongCustomerAuthentication = false;
  public sdaAuthenticationsSessionsView = false;
  public sdaAuthenticationsSessionsAuth = false;
  public sdaCustomerOnboardingActionsInitiate = false;
  public customerInstructionView = false;
  public customerInstructionInitiation = false;
  public customerInstructionUpdate = false;
  public customerInstructionDeletion = false;
  public customerInstructionSubmit = false;
  public paymentInstructionView = false;
  public paymentNetwork = false;
  public paymentContract = false;
  public consultAuthorizationHolds = false;
  public sdaCardView = false;
  public cardSensitiveDataView = false;
  public sdaAccountView = false;
  public recipientCreate = false;
  public sdaExchangeRateView = false;
  public sdaTransactionsView = false;
}
