import { FormCollectionInput, FormImageInput, FormInput, FormInputType } from "../../core/data-forms/form-input-types";
import { ImagesConverter, UploadedImage } from "../../utils/images-converter";
import { InitialOnboarding, OnboardingFile, OnboardingStatus } from "./onboarding";

import { isEqual } from "lodash";
import { FormFieldValueType } from "../../../mobile/core/data-forms/form-field";
import { isDefined } from "../../utils/assert";
import { Observable } from "../../utils/observable";
import { UrlLink } from "../BaseUrl";
import { FeaturesManager } from "../features/features-manager";
import { OnboardingService } from "./onboarding-service";
import { formatCollectionInputs } from "../../utils/format-collection-inputs.ts";

export class OnboardingManager {
  constructor(
    private onboardingService: OnboardingService,
    private featuresManager: FeaturesManager,
    private imagesConverter: ImagesConverter,
  ) {}

  onboardingFile = new Observable<OnboardingFile | null>(null);
  steps = new Observable<FormCollectionInput[]>([]);
  updated = new Observable<boolean>(true);
  updating = new Observable<boolean>(false);
  submitting = new Observable<boolean>(false);
  error = new Observable<string | null>(null);
  needOnboardingFileRedirect = new Observable<boolean>(false);
  allRequiredFieldsFilled = new Observable<boolean>(false);

  async initialize() {
    if (this.featuresManager.features.get().sdaCustomerOnboardingView) {
      try {
        const onboardingFile = await this.onboardingService.getOnboardingFile();
        if (onboardingFile) {
          this.onboardingFile.set(onboardingFile);
          const needOnboardingFileRedirect = [OnboardingStatus.INITIATED].includes(
            onboardingFile?.status as OnboardingStatus,
          );
          this.needOnboardingFileRedirect.set(needOnboardingFileRedirect);
          this.setFilteredSteps(onboardingFile.form.inputs);
          return {
            onboardingFile,
            needOnboardingFileRedirect,
          };
        }
      } catch (e) {
        if (e === "CLIENT_DELEGATE_USER_NOT_ALLOWED") {
          throw e;
        } else {
          console.log("error getting onboarding file", e);
        }
      }
    }
  }

  async initiate(data: InitialOnboarding) {
    try {
      await this.onboardingService.initiateOnboarding(data);
    } catch (e) {
      console.log("error initiating onboarding", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  async updateOnboardingFile(currentIndex: number) {
    this.error.set(null);
    this.updating.set(true);
    try {
      const rawCollection = this.steps.get()[currentIndex];
      const stepValues = this.getFlatCollectionData(rawCollection);
      const hasMissingRequiredFields = this.valuesHaveMissingRequiredFields(rawCollection.inputs);
      const hasValidationErrors = this.valuesHaveValidationErrors(rawCollection.inputs);

      if (!hasMissingRequiredFields.allCorrect) {
        throw JSON.stringify({
          message: "Missing required fields",
          fields: hasMissingRequiredFields.errorFields,
        });
      }

      if (!hasValidationErrors.allCorrect) {
        throw JSON.stringify({
          message: "Invalid fields",
          fields: hasValidationErrors.errorFields,
        });
      }
      const onboardingFile = await this.onboardingService.updateOnboardingFile(stepValues);
      this.onboardingFile.set(onboardingFile);
      this.needOnboardingFileRedirect.set(
        [OnboardingStatus.INITIATED].includes(onboardingFile?.status as OnboardingStatus),
      );
      this.setFilteredSteps(onboardingFile.form.inputs);
      this.updated.set(false);
    } catch (e) {
      const JSONError = JSON.parse(e);
      if (JSONError?.message === "Invalid fields") {
        this.error.set("Certains champs sont incorrects.");
        throw JSONError;
      } else if (JSONError?.message === "Missing required fields") {
        this.error.set("Certains champs sont requis.");
        throw JSONError;
      } else {
        this.error.set(e?.response?.data?.error?.message || e.toString());
        throw e?.response?.data?.error?.message || e.toString();
      }
    } finally {
      this.updating.set(false);
    }
  }

  hasInputValueChanged(value: FormFieldValueType, id: string, currentIndex: number) {
    const onboardingFile = this.onboardingFile.get();
    if (onboardingFile) {
      const oldValue = getInput(onboardingFile?.form.inputs[currentIndex], id).value;
      return !isEqual(oldValue, value);
    }
    return false;
  }

  stringToRegex(str) {
    const main = str.match(/\/(.+)\/.*/);
    const options = str.match(/\/.+\/(.*)/);
    if (main && main.length > 1 && options && options.length > 1) {
      return new RegExp(main[1], options[1]);
    }
    return null;
  }

  valuesHaveMissingRequiredFields(inputs: FormInput[]) {
    const result = {
      allCorrect: true,
      errorFields: [] as string[],
    };
    const requiredFields = inputs.filter((e) => e.required);
    requiredFields.forEach((input) => {
      const requiredFieldValue = input.value;
      if (
        (!requiredFieldValue && requiredFieldValue !== 0) ||
        requiredFieldValue === "" ||
        requiredFieldValue === null ||
        requiredFieldValue === undefined
      ) {
        //value not present or empty
        result.allCorrect = false;
        result.errorFields.push(input.id);
      }
    });
    console.log(result);
    return result;
  }

  valuesHaveValidationErrors(inputs: FormInput[]) {
    const result = {
      allCorrect: true,
      errorFields: [] as string[],
    };
    inputs.forEach((input) => {
      const fieldValue = input.value;
      if (!!input.validators && !!fieldValue) {
        if (
          input.validators.some((rule) => {
            //if one of the validators is evaluated to false
            const regex = this.stringToRegex(rule);
            if (regex) {
              return !regex.test(fieldValue?.toString());
            }
          })
        ) {
          result.allCorrect = false;
          result.errorFields.push(input.id);
        }
      }
    });
    return result;
  }

  getFlatCollectionData(collection: FormCollectionInput) {
    const data: { [key: string]: FormFieldValueType } = {};
    collection.inputs.forEach((input: FormInput) => {
      const fieldIsFileAndAlreadyUploaded = input.type === FormInputType.FileContent && typeof input.value !== "string";
      if (!fieldIsFileAndAlreadyUploaded) {
        data[input.id] = input.value as string;
      }
    });
    return {
      [collection.id]: data,
    };
  }

  fillResponse(input: FormInput, value: FormFieldValueType, currentIndex: number) {
    const steps = this.steps.get();
    if (steps !== null && this.hasInputValueChanged(value, input.id, currentIndex)) {
      const currentStep = steps[currentIndex];
      this.updated.set(true);
      getInput(currentStep, input.id).value = value;
      // Prevent app freeze when updating a lot of inputs
      setTimeout(() => {
        this.steps.set([...steps]);
      }, 0);
    }
  }

  async fillMultiImagesResponse(input: FormInput, values: (string | UploadedImage)[], currentIndex: number) {
    const isReady =
      values.reduce<number>((prev, value) => prev + (value ? 1 : 0), 0) >=
      ((input as FormImageInput).minimumPageCount ?? 1);

    let imageValue: string | undefined;
    if (isReady) {
      const filteredValues = values.filter((value) => value);
      imageValue = await this.imagesConverter.toPdf(filteredValues);
      if (isDefined(imageValue)) {
        imageValue = await this.imagesConverter.toBase64(imageValue);
      }
    }
    const steps = this.steps.get();
    if (steps !== null && steps.length > 0) {
      const currentStep = steps[currentIndex];
      this.updated.set(true);
      getInput(currentStep, input.id).value = imageValue;
      // Prevent app freeze when updating a lot of inputs
      setTimeout(() => {
        this.steps.set([...steps]);
      }, 0);
    }
  }

  async submitOnboardingFile() {
    this.error.set(null);
    this.submitting.set(true);
    try {
      await this.onboardingService.submitOnboardingFile();
      this.updated.set(false);
      await this.initialize();
    } catch (e) {
      console.log("error submitting onboarding file", e);
      this.error.set(e?.response?.data?.error?.message || e.toString());
    } finally {
      this.submitting.set(false);
    }
  }

  async getAttachmentPreview(attachmentId: string, links?: UrlLink[]) {
    try {
      const url = links?.find((l) => l.rel === "getDocumentPreview")?.href;
      const preview = await this.onboardingService.getAttachmentPreview(attachmentId, url);
      return preview;
    } catch (e) {
      console.log("error getting attachment preview", e);
    }
  }

  private setFilteredSteps(inputs: FormCollectionInput[]) {
    const filteredSteps = formatCollectionInputs(inputs);
    this.steps.set(filteredSteps);
  }
}

function getInput(collection: FormCollectionInput, inputId: string) {
  const input = collection.inputs.find((i: { id: string }) => i.id === inputId);

  if (!input) {
    throw new Error(`can't find input ${inputId} in collection ${collection.id}`);
  }

  return input;
}

export class OnboardingMissingFieldsError extends Error {
  constructor(msg, fields) {
    super(msg);
    this.fields = fields;
  }
  public fields: string[];
}
